body{
    // height: 195vh;
    width: 100%;
}
.container{
    margin:0%;
    padding: 0%;
    width: 100%; 
    height: 100vh;
    .nav-container-sample{
        position: -webkit-sticky;
            position: sticky;
            top:0;
        .nav {
            height: 50px;
            width: 100%;
            // background-color: #4d4d4d;
            background:rgba($color: #5d2e84, $alpha: 1);
            position: relative;
            
            > {
              .nav-header {
                display: inline;
                > .nav-title {
                  display: inline-block;
                  font-size: 22px;
                  color: #fff;
                  padding: 10px 10px 10px 10px;
                }
              }
              .nav-btn {
                display: none;
              }
              .nav-links {
                display: inline;
                float: right;
                font-size: 18px;
                > a {
                  display: inline-block;
                  padding: 13px 10px 13px 10px;
                  text-decoration: none;
                  color: #efefef;
                  &:hover {
                    background-color: rgba(0, 0, 0, 0.3);
                  }
                }
              }
              #nav-check {
                display: none;
              }
            }
          }
          
          @media (max-width: 600px) {
            .nav > {
              .nav-btn {
                display: inline-block;
                position: absolute;
                right: 0px;
                top: 0px;
                > label {
                  display: inline-block;
                  width: 50px;
                  height: 50px;
                  padding: 13px;
                  &:hover {
                    background-color: rgba(0, 0, 0, 0.3);
                  }
                  > span {
                    display: block;
                    width: 25px;
                    height: 10px;
                    border-top: 2px solid #eee;
                  }
                }
              }
              .nav-links {
                position: absolute;
                display: block;
                width: 100%;
                background-color: #333;
                height: 0px;
                transition: all 0.3s ease-in;
                overflow-y: hidden;
                top: 50px;
                left: 0px;
                > a {
                  display: block;
                  width: 100%;
                }
              }
              #nav-check {
                &:not(:checked) + .nav-links {
                  height: 0px;
                }
                &:checked + .nav-links {
                  height: calc(100vh - 320px);
                  overflow-y: auto;
                }
              }
            }
          }
    }
    .carousal{
        background-image: url('./images/flight.jpg');
        width: 100%;
        height: 90vh;
        // background-position: 100%;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        .right{
            display: none;
            cursor: pointer;
            position: absolute;
            right: 0;
            width: 0;
            height: 0;
            border-top: 20.5vw solid transparent;
            border-left: 100px solid rgba($color: #5d2e84, $alpha: 1);
            opacity: 0.2;
            border-bottom: 20.5vw solid transparent;
            &:hover{
                opacity: 0.4;
            }
        }
        .left{
            display: none;
            cursor: pointer;
            position: absolute;
            left:0;
            width: 0;
            height: 0;
            border-top: 42.5vh solid transparent;
            border-right: 100px solid rgba($color: #5d2e84, $alpha: 1);
            opacity: 0.2;
            border-bottom: 42.5vh solid transparent;
            &:hover{
                opacity: 0.4;
            }
        }
        &:hover{
            
        }
        .contentin-carousal{           
            // width:30vw;
            // height: 55vh;
            // @import url('https://fonts.googleapis.com/css?family=Staatliches');
            // font-family: 'Staatliches', cursive;
            // color: white;
            // position: absolute;
            // left:10%;
            // top:27%;
            // display: inline;
            // border: 1px solid;
            // padding: 10px;
            // // box-shadow: 5px 10px #888888;
            // box-shadow: 5px 10px rgba($color: #ac6e3462, $alpha: 0.5);
            
        }
    }

    .content-window{
      .home-content{              
        color: white;  
        display: grid;
        grid-template-columns: 25% 80%;
        .home-heading{
          height: 5vh;
          padding: 3%;
          font-size: 35px;        
        }
        .home-aboutUs{
          width: 100%;
        }
        padding: 2%; 
            
      }
        width:100%;
        height: 59vh;
        // position: relative;        
        background:rgba($color: #5d2e84, $alpha: .8);
        h1{
          margin-top: -.1%;
        } 
        // padding: 2%;
    }

    .Footer{
      // position: relative;
        // width:100%;
        // height:38.9vh;
        // background: rgba($color: rgb(98, 0, 255),$alpha:.9);
        background:rgba($color: #5d2e84, $alpha: .8);
        display: grid;
        bottom:0;
        // padding: 1.5%;        
        grid-template-columns: repeat(2,50%);
        div{          
            color:white;
            padding: 2em;            
            // width:97%;
            // background-color: darkslategrey;
            // background: rgba(193, 195, 204,.3);
            // background: white;
            p{
              color: unset;
            }
        }
    }
   
   
}