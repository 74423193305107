@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  width: 100%; }

.container {
  margin: 0%;
  padding: 0%;
  width: 100%;
  height: 100vh; }
  .container .nav-container-sample {
    position: -webkit-sticky;
    position: sticky;
    top: 0; }
    .container .nav-container-sample .nav {
      height: 50px;
      width: 100%;
      background: #5d2e84;
      position: relative; }
      .container .nav-container-sample .nav > .nav-header {
        display: inline; }
        .container .nav-container-sample .nav > .nav-header > .nav-title {
          display: inline-block;
          font-size: 22px;
          color: #fff;
          padding: 10px 10px 10px 10px; }
      .container .nav-container-sample .nav > .nav-btn {
        display: none; }
      .container .nav-container-sample .nav > .nav-links {
        display: inline;
        float: right;
        font-size: 18px; }
        .container .nav-container-sample .nav > .nav-links > a {
          display: inline-block;
          padding: 13px 10px 13px 10px;
          text-decoration: none;
          color: #efefef; }
          .container .nav-container-sample .nav > .nav-links > a:hover {
            background-color: rgba(0, 0, 0, 0.3); }
      .container .nav-container-sample .nav > #nav-check {
        display: none; }
    @media (max-width: 600px) {
      .container .nav-container-sample .nav > .nav-btn {
        display: inline-block;
        position: absolute;
        right: 0px;
        top: 0px; }
        .container .nav-container-sample .nav > .nav-btn > label {
          display: inline-block;
          width: 50px;
          height: 50px;
          padding: 13px; }
          .container .nav-container-sample .nav > .nav-btn > label:hover {
            background-color: rgba(0, 0, 0, 0.3); }
          .container .nav-container-sample .nav > .nav-btn > label > span {
            display: block;
            width: 25px;
            height: 10px;
            border-top: 2px solid #eee; }
      .container .nav-container-sample .nav > .nav-links {
        position: absolute;
        display: block;
        width: 100%;
        background-color: #333;
        height: 0px;
        transition: all 0.3s ease-in;
        overflow-y: hidden;
        top: 50px;
        left: 0px; }
        .container .nav-container-sample .nav > .nav-links > a {
          display: block;
          width: 100%; }
      .container .nav-container-sample .nav > #nav-check:not(:checked) + .nav-links {
        height: 0px; }
      .container .nav-container-sample .nav > #nav-check:checked + .nav-links {
        height: calc(100vh - 320px);
        overflow-y: auto; } }
  .container .carousal {
    background-image: url(/static/media/flight.447c2a49.jpg);
    width: 100%;
    height: 90vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    .container .carousal .right {
      display: none;
      cursor: pointer;
      position: absolute;
      right: 0;
      width: 0;
      height: 0;
      border-top: 20.5vw solid transparent;
      border-left: 100px solid #5d2e84;
      opacity: 0.2;
      border-bottom: 20.5vw solid transparent; }
      .container .carousal .right:hover {
        opacity: 0.4; }
    .container .carousal .left {
      display: none;
      cursor: pointer;
      position: absolute;
      left: 0;
      width: 0;
      height: 0;
      border-top: 42.5vh solid transparent;
      border-right: 100px solid #5d2e84;
      opacity: 0.2;
      border-bottom: 42.5vh solid transparent; }
      .container .carousal .left:hover {
        opacity: 0.4; }
  .container .content-window {
    width: 100%;
    height: 59vh;
    background: rgba(93, 46, 132, 0.8); }
    .container .content-window .home-content {
      color: white;
      display: grid;
      grid-template-columns: 25% 80%;
      padding: 2%; }
      .container .content-window .home-content .home-heading {
        height: 5vh;
        padding: 3%;
        font-size: 35px; }
      .container .content-window .home-content .home-aboutUs {
        width: 100%; }
    .container .content-window h1 {
      margin-top: -.1%; }
  .container .Footer {
    background: rgba(93, 46, 132, 0.8);
    display: grid;
    bottom: 0;
    grid-template-columns: repeat(2, 50%); }
    .container .Footer div {
      color: white;
      padding: 2em; }
      .container .Footer div p {
        color: unset; }

/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* body {
  background: #EEEEF4;
  color:#999;
  font-family:Roboto;
} */

h1{
  font-weight:100;
  font-size:27pt;
  color:white;
}

p{font-weight:300;color:white;}

.warning-content {
	/* position:absolute;
  top:25%;
  width:100%;
  height:300px; */  
  text-align:center;
  margin:0;
  /* margin-top: 15%; */
  
}
/* .first{
animation: rotateTest infinite 4s;

}
@keyframes rotateTest{
    from{
        -ms-transform-origin: center; 
        -webkit-transform-origin: center;
        transform-origin: center;
        transform: rotate(20deg);
    }
    to{
        -ms-transform-origin: center; 
        -webkit-transform-origin: center;
        transform-origin: center;
        transform: rotate(320deg);
    }
} */
