@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700);

/* body {
  background: #EEEEF4;
  color:#999;
  font-family:Roboto;
} */

h1{
  font-weight:100;
  font-size:27pt;
  color:white;
}

p{font-weight:300;color:white;}

.warning-content {
	/* position:absolute;
  top:25%;
  width:100%;
  height:300px; */  
  text-align:center;
  margin:0;
  /* margin-top: 15%; */
  
}
/* .first{
animation: rotateTest infinite 4s;

}
@keyframes rotateTest{
    from{
        -ms-transform-origin: center; 
        -webkit-transform-origin: center;
        transform-origin: center;
        transform: rotate(20deg);
    }
    to{
        -ms-transform-origin: center; 
        -webkit-transform-origin: center;
        transform-origin: center;
        transform: rotate(320deg);
    }
} */